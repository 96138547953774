#root {
  background-color: #F7F7FA;
  /*background-color: #D8D7E2;*/
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1.1em;
}

header {
  margin-bottom: 0.9em;
  display: flex;
}

.heading {
  font-size: 1.5em;
  font-weight: 800;
  flex-grow: 70;
}

.card-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

