.search-container {
    width: 50%;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.search-container-top {
    width: 50%;
    
	position: absolute;
	top: 10%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.nes-btn.search-button {
	position: absolute;
	right: -8px;
	top: -2px;
}

.search-bar {
	position: relative;
}
